.allmaterialsprice-section {
  .top-section {
    margin-top: 6px;
    margin-bottom: 24px;
    width: 100%;
    border-radius: 12px;
    height: 50px;
    background-color: #e6e6e6;
    h5 {
      font-weight: 400;
      font-size: 16px;
      color: #1b1b1b;
    }
  }
  .middle-section {
    margin-bottom: 11px;
    input {
      padding-left: 7px;
      width: 100%;
      height: 50px;
      background-color: #ffffff;
      border: none;
      border-radius: 8px;
    }
  }
  .bottom-section {
    margin-bottom: 24px;
    table {
      width: 100%;
      border-collapse: separate;
      table-layout: fixed;
      tr {
        td {
          color: #6d7280;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          padding: 16px 10px 16px 10px;
          height: 50px;
          border-radius: 8px;
          background-color: #ffffff;
        }
      }
    }
  }
  .button-section {
    margin-bottom: 12px;
    .share-with-friend {
      width: 100%;
      background-color: #ffffff;
      color: #8c920b;
      border: 1px solid #8c920b;
    }
  }
  .modal-dialog {
    min-height: 266px;
    margin: 0;
    padding: 0;
    width: 92%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    .modal-header {
      padding: 16px 16px 0 16px;
      border: none !important;
      i {
        color: var(--primary-color);
      }
    }
    .modal-body {
      h6 {
        margin: 0 !important;
        color: #232323;
        font-weight: 400;
        font-size: 14px;
      }
      .social-media-section {
        margin-top: 10px;
        margin-bottom: 16px;
        i {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 56px;
          height: 56px;
          font-size: 28px;
          border: 1px solid #d7d7d7;
          border-radius: 50%;
          padding: 12px;
        }
        .fa-whatsapp {
          color: #25d366;
        }
        .fa-telegram {
          color: #34aadf;
        }
        .fa-facebook {
          color: #0866ff;
        }
        .fa-instagram {
          background: linear-gradient(
            45deg,
            #feda75,
            #fa7e1e,
            #d62976,
            #962fbf,
            #4f5bd5
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .fa-discord {
          color: #5865f2;
        }
      }
    }
    hr {
      width: 90%;
    }
    .modal-footer {
      border: none !important;
      .share-link-section {
        width: 100%;
        min-height: 56px;
        border: 1px solid #8c920b73;
        .left-section {
          h5 {
            margin: 0;
          }
        }
        .right-section {
          button {
            width: 88px;
            height: 44px;
            background-color: var(--primary-color);
            color: #ffffff;
          }
        }
      }
    }
  }
}
.price-color {
  color: #9f6438 !important;
}
