.entry-section {
  height: 800px;
  display: flex;
  flex-direction: column;
  //   align-items: center;
  justify-content: center;
  .top-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    img {
      width: 250px;
    }
  }
  .bottom-section {
    .section {
      background-color: #ffffff;
      padding: 10px 12px;
      border-radius: 36px;
      .flag-section {
        img {
          width: 34px;
          height: 34px;
        }
      }
      .text-section {
        h4 {
          margin: 0;
          color: #101010;
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
  }
}
