.account-home-plan-section {
  .middle-section {
    margin-top: 36px;
    .box {
      height: 120px;
      border-radius: 12px;
      padding: 25px 50px;
      background-color: #ffffff;
      .property-icon {
        i {
          font-size: 32px;
          color: var(--primary-color);
          width: 100%;
          margin-bottom: 20px;
        }
      }
      .property-title {
        h5 {
          margin: 0 !important;
          color: #4b5563;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
  }
}
