.myhome-section {
  h4 {
    color: #101010;
    margin-top: 6px;
    font-weight: 600;
    font-size: 14px;
  }
  .section {
    padding: 12px 19.5px 12px 21.5px;
    width: 100%;
    height: 48px;
    background-color: #ffffff;
    h2 {
      margin-left: 14px;
      color: #101010;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    .left-section {
      i {
        color: var(--primary-color);
      }
    }
    .right-section {
      .property-title {
        h2 {
          margin: 0;
          color: #101010;
          font-weight: 400;
          font-size: 14px;
        }
      }
      .detail-section {
        i {
          color: #aaaaaf;
        }
      }
    }
  }
}
