.material-section {
  position: relative;
  overflow-x: hidden;
  .top-section {
    margin-bottom: 21px;

    .room-handle-section {
      width: 100%;
      top: 0;
      left: 0;
      .MuiBox-root {
        text-align: center;
        background-color: transparent;
        border: 1px solid #000000;
        backdrop-filter: blur(8px); /* Apply blur effect */
        -webkit-backdrop-filter: blur(8px); /* Safari support */
        display: flex;
        justify-content: center; /* Space between text and icon */
        align-items: center;
        width: 100%;
        font-weight: 600;
        font-size: 20px;
        input {
          color: #000000 !important;
        }
      }
      .MuiSelect-select {
        font-weight: 600;
        font-size: 20px;
        text-align: start;
        margin-top: 5px;
        color: #000000 !important;
      }
      svg {
        color: #000000;
      }
    }
    .image-on-room-section {
      display: flex;
      flex-direction: column;
      height: 358px;
      width: 358px;
      border-radius: 12px;
      overflow: hidden;
      margin: 0 auto;

      .full-room-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
      }

      .ceiling-section {
        height: 28px;
        width: 358px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .wall-section {
        height: 296px;
        width: 358px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .floor-section {
        height: 34px;
        width: 358px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .middle-section {
    margin-bottom: 24px;
    .material-category-buttons {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      min-width: 100px;
      font-weight: 600;
      font-size: 16px;
      line-height: 14px;
      padding: 16p 24px 18px 24px;
      border-radius: 8px;
      background-color: #6f6f6f;
      color: #ffffff;
    }
  }
  .dropdown-section {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 96%;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 12px;
    padding: 12px;
    transition: height 0.3s ease;
    z-index: 1000;
    -webkit-transform: translateX(-50%);

    .dropdown-content {
      height: 30vh;
      -webkit-overflow-scrolling: touch;
      .material-box {
        overflow-y: scroll;
        img {
          width: 90px;
          height: 90px;
          border-radius: 10px;
          border: 1px solid #dfdfdf;
        }
        h5 {
          text-align: start;
          margin-bottom: 0 !important;
          margin-top: 5px;
          color: #000000;
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          letter-spacing: -0.32px;
        }
        p {
          text-align: start;
          margin: 0 !important;
          color: #797979;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.32px;
        }
      }
    }
  }
  .button-section {
    margin-top: 42px;
    margin-bottom: 12px;
  }
}
.selected {
  border: 1px solid #8c920b;
}
.selected-materials-section {
  margin-top: 24px;
  padding: 0 12px;

  .selected-materials-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .room-materials {
    .room-name {
      font-size: 16px;
      font-weight: 500;
      color: #6f6f6f;
      margin-bottom: 12px;
    }
  }

  .selected-materials-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 16px;

    .selected-material-item {
      text-align: center;

      img {
        width: 90px;
        height: 90px;
        border-radius: 10px;
        border: 1px solid #dfdfdf;
        margin-bottom: 8px;
      }

      p {
        font-size: 12px;
        color: #000000;
        margin: 0;
      }
    }
  }
}
