.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  outline: none !important;
  border: none !important;
}
.MuiBox-root {
  width: 100% !important;
}
// .MuiOutlinedInput-notchedOutline {
//   border: none !important;
//   outline: none !important;
// }
