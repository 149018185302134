.home-section {
  h5 {
    color: #232323;
    font-size: 500;
    margin: 0;
    font-size: 14px;
  }
  .property-types {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    margin-top: 11px;
    margin-bottom: 12px;
    button {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      width: 80px;
      height: 76px;
      font-size: 12px;
      border-radius: 12px;
      .icon {
        margin-top: 10px;
        margin-bottom: 7px;
      }
    }
  }
  .property-status-section {
    .box {
      background-color: transparent;
      width: 100%;
      height: 50px;
      border-radius: 8px;
      border: 1px solid #e3e3e3;
      i {
        color: #9c9c9c;
        font-size: 24px;
      }
      h4 {
        margin: 0;
        color: #9c9c9c;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  .design-section {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    margin-bottom: 14px;
    .design-title {
      color: #374151;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
    .design-rooms {
      .room {
        border-radius: 14px;
        flex-shrink: 0;
        .d-flex {
          padding: 10px;
          h4 {
            color: #4b5563;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
          }
        }
        .design-style-image {
          max-width: 170px !important;
          max-height: 180px;
        }
        img {
          width: 100%;
          height: 180px;
          border-radius: 14px;
          box-shadow: 0px 1px 4px 0px #00000040;
        }
      }
    }
  }
  .definition-section {
    margin-bottom: 18px;
    .definition-title {
      color: #374151;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
    .accordion-content {
      max-height: 100%; // Ensure content can expand within the section
      overflow-y: auto; // Allow vertical scrolling within the accordion
    }
  }
}

// Hide scrollbar for WebKit browsers (e.g., Chrome, Safari)
::-webkit-scrollbar {
  display: none;
}
