.allmaterials-section {
  .top-section {
    margin-top: 6px;
    margin-bottom: 24px;
    width: 100%;
    border-radius: 12px;
    height: 50px;
    background-color: #e6e6e6;
    h5 {
      font-weight: 500;
      font-size: 16px;
      color: #1b1b1b;
    }
  }
  .middle-section {
    margin-bottom: 11px;
    input {
      padding-left: 7px;
      width: 100%;
      height: 50px;
      background-color: #ffffff;
      border: none;
      border-radius: 8px;
    }
  }
  .bottom-section {
    margin-bottom: 48px;
    table {
      width: 100%;
      border-collapse: separate;
      table-layout: fixed;
      tr {
        width: 100%;
        td {
          color: #6d7280;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          padding: 16px 10px 16px 10px;
          height: 50px;
          border-radius: 8px;
          background-color: #ffffff;
        }
      }
    }
  }
}
