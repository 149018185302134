// Font Awesome
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css");
// Pages
@import "../../pages/Onboarding/Onboarding";
@import "../../pages/Entry/Entry";
@import "../../pages/Home/Home";
@import "../../pages/Calculator/Calculator";
@import "../../pages/DimensionRoom/DimensionRoom";
@import "../../pages/Material/Material";
@import "../../pages/Maintenance/Maintenance";
@import "../../pages/Signup/Signup";
@import "../../pages/OTPverification/OTPverification.scss";
@import "../../pages/SuccessLogin/SuccessLogin.scss";
@import "../../pages/AllMaterials/AllMaterials";
@import "../../pages/AllMaterialsPrice/AllMaterialsPrice";
@import "../../pages/Account/Account";
@import "../../pages/SharedWithFriend/SharedWithFriend";
@import "../../pages/Account/Terms/Terms";
@import "../../pages/Account/PrivacyPolicy/PrivacyPolicy";
@import "../../pages/Account/Details/AccountDetails";
@import "../../pages/Account/MyForeman/MyForeman";
@import "../../pages/Account/MyHome/MyHome";
@import "../../pages/Account/MyHome/MyHomeDetails/MyHomeDetails";
@import "../../pages/Account/Details/Edit/AccountDetailsEdit";
@import "../../pages/Account/HomePlan/HomePlan";
@import "../../pages/Account/HomePlan/HomePlanDetails/HomePlanDetails";
@import "../../pages/Account/Contact/Contact";
@import "../../pages/Account/RepairHistory/RepairHistory";
@import "../../components/RoomDropdown/RoomDropdown";
@import "../../pages/admin/components/PropertyTypeManager/PropertyTypeManager";
@import "../../pages/admin/components/PropertyStatusManager/PropertyStatusManager";
@import "../../pages/admin/components/RepairTariffManager/RepairTariffManager";
@import "../../pages/admin/components/RepairStyleManager/RepairStyleManager";
@import "../../pages/admin/components/MaterialCategoryManager/MaterialCategoryManager";
@import "../../pages/admin/components/MaterialManager/MaterialManager";
@import "../../pages/admin/components/DependentMaterialManager/DependentMaterialManager";
@import "../../pages/admin/components/RoomTypeManager/RoomTypeManager";
@import "../../pages/admin/components/RepairManManager/RepairManManager";
@import "../../pages/admin/components/RepairTeamManager/RepairTeamManager";
@import "../../pages/admin/components/SpaceManager/SpaceManager";
@import "../../pages/admin/components/StoreManager/StoreManager";
@import "../../pages/admin/components/TechnicalAreaManager/TechnicalAreaManager";
@import "../../pages/Shops/Shops";
@import "../../pages/Login/Login";
@import "../../pages/admin/pages/Dashboard";
@import "../../pages/admin/pages/AdminLogin";
// @media (max-width: 768px) {
//   .mobile-view {
//     position: relative;
//     width: 100%;
//     height: auto;
//     border-radius: 10px;
//     overflow: auto;
//     .Toastify__toast-container {
//       position: absolute;
//     }
//   }
// }

:root {
  --primary-color: #8c920b;
}
body {
  background-color: #f4f4f6;
  font-family: "Inter", sans-serif;
}
.global-button {
  border-radius: 8px;
  width: 100%;
  background-color: #8c920b;
  border: 1px solid #8c920b !important;
  color: #ffffff;
}
.global-button:hover {
  color: #8c920b;
  background-color: transparent;
}
.MuiInputBase-root {
  background-color: #ffffff;
}
// Header

.language--change {
  margin-right: 6px !important;
  padding: 0 !important;
}
.ant-select-arrow {
  display: none !important;
}
.ant-select-selection-item {
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
// .ant-select-selector {
//   border: none !important;
//   outline: none !important;
//   background-color: transparent !important;
// }
.ant-select-selection-search {
  padding: 0 !important;
}

// Header Title
header {
  .page-title {
    h4 {
      margin: 0 !important;
      color: #000000;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
    }
  }
}
// Button sticky
