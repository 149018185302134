.material-manager {
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin: 2rem 0;

  h3,
  h4,
  h5 {
    color: #2c3e50;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  form {
    background: #f8f9fa;
    padding: 2rem;
    border-radius: 8px;
    margin-bottom: 2rem;

    label {
      font-weight: 500;
      color: #495057;
      margin-bottom: 0.3rem;
    }

    input,
    select {
      padding: 0.5rem 1rem;
      border: 1px solid #dee2e6;
      border-radius: 6px;
      transition: border-color 0.2s ease;
      margin-bottom: 1rem;

      &:focus {
        outline: none;
        border-color: #0d6efd;
        box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
      }
    }

    .selectable-checkbox {
      input[type="checkbox"] {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    .btn-primary {
      padding: 0.6rem 2rem;
      font-weight: 500;
      transition: all 0.3s ease;
      background-color: #3d348b !important;
      border-color: #3d348b !important;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(61, 52, 139, 0.2);
      }
    }
  }

  .table-responsive {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

    table {
      margin: 0;

      thead {
        background: #f8f9fa;

        th {
          padding: 1rem;
          font-weight: 600;
          color: #2c3e50;
          border-bottom: 2px solid #dee2e6;
        }
      }

      tbody {
        td,
        th {
          padding: 1rem;
          vertical-align: middle;
        }

        img {
          object-fit: cover;
          border: 1px solid #dee2e6;
        }

        .btn {
          border: none;
          padding: 0.5rem 1rem;
          font-weight: 500;
          font-size: 0.875rem;
          border-radius: 6px;
          transition: all 0.2s ease;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          min-width: 90px;

          &.btn-outline-warning {
            color: #fff;
            background: linear-gradient(135deg, #ffa726, #fb8c00);
            box-shadow: 0 2px 4px rgba(255, 167, 38, 0.2);

            &:hover {
              background: linear-gradient(135deg, #fb8c00, #f57c00);
              transform: translateY(-2px);
              box-shadow: 0 4px 8px rgba(255, 167, 38, 0.3);
            }
          }

          &.btn-outline-danger {
            color: #fff;
            background: linear-gradient(135deg, #ef5350, #e53935);
            box-shadow: 0 2px 4px rgba(239, 83, 80, 0.2);

            &:hover {
              background: linear-gradient(135deg, #e53935, #d32f2f);
              transform: translateY(-2px);
              box-shadow: 0 4px 8px rgba(239, 83, 80, 0.3);
            }
          }
        }
      }
    }
  }

  .dependent-materials {
    background: #f8f9fa;
    padding: 1.5rem;
    border-radius: 8px;
    margin: 1.5rem 0;

    .material-select {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      margin-bottom: 1.5rem;
    }

    ol {
      list-style: none;
      padding: 0;
      margin: 0;
      display: grid;
      gap: 0.5rem;

      li {
        button {
          width: 100%;
          text-align: left;

          &.active,
          &:active {
            background-color: #3d348b !important;
            border-color: #3d348b !important;
            color: white !important;
          }
        }
      }
    }
  }
}
