.calculator-section {
  .top-section {
    img {
      width: 100%;
      border-radius: 12px 12px 0 0;
    }
    .text-section {
      background-color: var(--primary-color);
      border-radius: 0 0 12px 12px;
      h4 {
        padding: 10px 26px 11px 25px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
      }
    }
  }
  .middle-section {
    width: 100%;
    margin-bottom: 18px;
    label {
      color: #374151;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
    }
    input {
      padding-left: 7px;
      width: 100%;
      height: 50px;
      background-color: #ffffff;
      border: none;
      border-radius: 8px;
    }
  }
  .bottom-section {
    h4 {
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: #374151;
      margin-bottom: 18px;
    }
    .bottom-top {
      margin-bottom: 24px;
    }
    .bottom-bottom {
      margin-bottom: 36px;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
      font-size: 32px;
      padding: 16px;
      border-radius: 10px;
      background-color: #dedede;
      color: #1b1b1b;
    }
    p {
      margin: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
      font-size: 20px;
      padding: 16px;
      border-radius: 10px;
      background-color: #ffffff;
    }
  }
}
input {
  width: 100%;
}

textarea {
  width: 100%;
}

form {
  width: 100%;
}
.error-input {
  border: 1px solid red !important;
}
.error-border {
  border: 1px solid red !important;
  padding: 4px; /* Kenarlığın görünmesini sağlamak için */
}
