.signup-section {
  h2 {
    margin-top: 36px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #374151;
  }
  .top-section {
    .name-surname-section {
      margin-bottom: 12px;
      label {
        color: #374151;
        font-weight: 600;
        font-size: 16px;
      }
      input {
        padding-left: 7px;
        width: 100%;
        height: 50px;
        background-color: #ffffff;
        border: none;
        border-radius: 8px;
      }
    }
    .phone-number-section {
      .PhoneInputCountry {
        border-radius: 8px;
        padding: 2px 16px;
        background-color: #ffffff !important;
      }
      margin-bottom: 20px;
      input {
        padding: 10px;
        width: 100%;
        height: 50px;
        background-color: #ffffff;
        border: none;
        border-radius: 8px;
      }
      label {
        color: #374151;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
  .middle-section {
    .line {
      width: 100% !important;
      border: 1px solid #d2d5da;
    }
  }
  .bottom-section {
    .button-section {
      margin-bottom: 36px;
      button {
        border: 1px solid #000000;
        width: 100%;
        background-color: transparent;
        color: #374151;
      }
      button:hover {
        background-color: #ffffff;
      }
    }
    .sign-in-section {
      h6 {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
      .login {
        color: #9ca3af;
      }
    }
  }
}
