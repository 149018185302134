.otpverification-section {
  .top-section {
    h2 {
      margin-top: 35px;
      margin-bottom: 20px;
      color: #374151;
      font-weight: 600;
      font-size: 24px;
      line-height: 18px;
    }
    p {
      color: #676f7d;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      margin-bottom: 11px;
    }
  }
  .bottom-section {
    p {
      color: #7e9db1;
      font-weight: 600;
      font-size: 14px;
      line-height: 26px;
    }
    form {
      input {
        text-align: center;
        color: #6d7280;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        width: 100%;
        height: 60px;
        border-radius: 12px;
        border: 1px solid #d9d9d9;
      }
    }
    h6 {
      margin-bottom: 38px;
      text-align: center;
      color: #8c920b;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
