.dashboard-container {
  min-height: 100vh;
  background: rgb(191, 194, 191);
  padding: 20px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    opacity: 0.2;
    z-index: 0;
  }

  > * {
    position: relative;
    z-index: 1;
  }

  .dashboard-title {
    color: #020202;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .dashboard-card {
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
    }
  }

  .dashboard-btn {
    width: 100%;
    height: 100%;
    padding: 25px;
    border: none;
    background: white;
    color: #2c3e50;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: center;
    transition: all 0.3s ease;

    &:hover {
      background: #f8f9fa;
      color: #1a252f;
    }

    i {
      font-size: 1.3rem;
    }
  }
}

.admin-layout {
  min-height: 100vh;
  background: #f5f6fa;
  display: flex;

  .admin-content {
    flex: 1;
    margin-left: 280px;
    padding: 2rem;
    background: #f5f6fa;
    min-height: 100vh;

    @media (max-width: 768px) {
      margin-left: 0;
    }
  }
}

.dashboard-wrapper {
  display: flex;
  min-height: 100vh;
  position: relative;
}

.main-content {
  flex: 1;
  margin-left: 280px;
  padding: 20px;
  transition: margin-left 0.3s ease;
  width: calc(100% - 280px);
  min-height: 100vh;
  background: #f5f6fa;
  position: relative;

  &.content-expanded {
    margin-left: 80px;
    width: calc(100% - 80px);
  }
}

.sidebar-container {
  position: fixed;
  height: 100vh;
  background: #3d348b;
  width: 280px;
  transition: all 0.3s ease;
  z-index: 1005;
  overflow-y: auto;

  &.collapsed {
    width: 80px;

    .sidebar-logo {
      width: 50px !important;
    }

    .sidebar-item {
      span {
        display: none;
      }
      i {
        margin: 0 auto;
      }
    }

    .sidebar-toggle {
      left: 55px;
    }
  }
}

.sidebar-toggle {
  width: 100%;
  height: 100%;
  padding: 20px;
  border: none;
  background: white;
  color: #2c3e50;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  transition: all 0.3s ease;

  &:hover {
    background: #f8f9fa;
    color: #1a252f;
  }

  i {
    font-size: 1.3rem;
  }
}

.sidebar-header {
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .sidebar-logo {
    width: 150px !important;
    height: auto;
  }

  .sidebar-toggle {
    position: fixed;
    left: 255px;
    top: 10px;
    background: #3d348b;
    border: 1px solid #ffffff;
    color: white;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    transition: all 0.3s ease;
    font-size: 6px;

    &:hover {
      background: #322b70;
      transform: scale(1.1);
    }
  }
}

.sidebar-menu {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.sidebar-item {
  width: 100%;
  padding: 0.8rem 1rem;
  background: transparent;
  border: none;
  color: white;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  transition: all 0.3s ease;
  border-radius: 5px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  i {
    font-size: 1.1rem;
  }

  span {
    font-size: 0.9rem;
  }

  &.exit-button {
    background: #e84855;
    margin-top: auto;

    &:hover {
      background: #d43545;
    }
  }
}

.mobile-header {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: #3d348b;
  padding: 0 20px;
  z-index: 1004;
  align-items: center;
  justify-content: space-between;

  .mobile-logo {
    height: 40px;
  }

  .mobile-menu-toggle {
    background: transparent;
    border: none;
    color: white;
    font-size: 24px;
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 5px;
    }
  }
}

@media (max-width: 768px) {
  .mobile-header {
    display: flex;
  }

  .sidebar-container {
    transform: translateX(-100%);
    width: 280px !important;
    top: 60px;
    height: calc(100vh - 60px);

    &.mobile-open {
      transform: translateX(0);
    }

    .sidebar-header {
      display: none;
    }
  }

  .main-content {
    margin-left: 0 !important;
    width: 100% !important;
    padding: 20px;
    margin-top: 60px;
  }

  .sidebar-toggle {
    display: none !important;
  }
}

.sidebar-container.mobile-open::after {
  content: "";
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}
