.account-details-edit-section {
  background-color: #f4f4f6;
  header {
    margin-top: 14px;
    padding-bottom: 30px;
    .link {
      text-decoration: none;
    }
    h1 {
      margin: 0 !important;
      color: #000000;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
    }
    i {
      font-size: 16px;
      color: #8c920b;
    }
  }
  .top-section {
    margin-bottom: 32px;
    h5 {
      color: #8c920b;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      border-bottom: 1px solid #8c920b;
    }
    img {
      width: 85px;
      border-radius: 50%;
    }
  }
  .middle-section {
    margin-bottom: 32px;
    form {
      .name-section,
      .surname-section,
      .email-section,
      .phone-number-section,
      .adress-section {
        border-bottom: 1px solid #d2d5da;
        margin-bottom: 20px;
      }
      input {
        color: #101010;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding: 10px 0;
        background-color: transparent;
        border: none;
        outline: none;
      }
      label {
        color: #9ca3af;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
