.admin-login-section {
  background-color: #f8f9fa;
  min-height: 100vh;
  position: relative;

  .iframe-container {
    border-right: 1px solid rgba(222, 226, 230, 1);
  }

  .vertical-divider {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 70%;
    border-left: 1px solid #dee2e6;
  }

  .login-form-container {
    max-width: 450px;
    margin: 0 auto;
    background: none;

    h2 {
      color: #333;
      font-weight: 600;
    }

    .form-control {
      padding: 12px;
      border-radius: 5px;
      border: 1px solid #ddd;

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        border-color: #80bdff;
      }
    }

    .btn-primary {
      padding: 12px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 2px;
      border-radius: 8px;

      &:hover {
        transform: translateY(-1px);
        transition: transform 0.2s ease;
      }
    }
  }
}
