.privacy-policy-section {
  .top-section {
    margin-top: 27px;
  }
  .bottom-section {
    margin-top: 24px;
    .privacy-policy-title {
      color: #6d7280;
      font-weight: 500;
      font-size: 20px;
    }
    .description {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: max-height 0.4s ease, opacity 0.4s ease;
    }
    .description.open {
      max-height: 500px;
      opacity: 1;
    }
    i {
      cursor: pointer;
    }
  }
}
