.home-plan-details-section {
  .top-section {
    margin-top: 32px;
    margin-bottom: 32px;
    .property {
      margin-bottom: 20px;
      .property-icon {
        i {
          font-size: 24px;
          color: var(--primary-color);
        }
      }
      .property-title {
        h5 {
          margin: 0;
          color: #4b5563;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }

    label {
      margin-bottom: 10px;
      color: #6d7280;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
    }
    input {
      height: 50px;
      border: none;
      outline: none;
      background-color: transparent;
      border-radius: 8px;
      padding: 15px 10px;
      color: #7d868d;
      font-weight: 500;
      font-size: 20px;
    }
  }
  .room-section {
    h5 {
      margin-bottom: 20px;
      color: #4b5563;
      font-weight: 600;
      font-size: 18px;
    }
    .room-title {
      margin: 0 !important;
      border-radius: 8px 8px 0 0;
      padding: 20px 16px;
      background-color: #ffffff;
      h3 {
        margin: 0 !important;
        color: #6d7280;
        font-weight: 500;
        font-size: 20px;
        list-style: 21px;
        letter-spacing: -0.32px;
      }
    }
    .room {
      border-radius: 0 0 8px 8px;
      padding: 20px 16px;
      background-color: #ffffff;
      form {
        label {
          color: #6d7280;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
        }
        input {
          margin-top: 11px;
          background-color: #f4f4f4;
          color: #7d868d;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.5px;
          border-radius: 8px;
          padding: 15px 10px 15px 16px;
          width: 100%;
          border: none;
          outline: none;
        }
      }
    }
  }
}
