.succcesslogin-section {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-bottom: 38px;
  }
  h4 {
    margin-bottom: 12px;
    color: #6d7280;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
  }
  p {
    color: #6d7280;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
