.onboarding-section {
  // margin-top: 16px;
  .img-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 42px;
    margin-top: 10px;
    width: 100%;
    .img {
      max-width: 100%;
      max-height: 100%;
      cursor: pointer;
    }
  }
  .text-section {
    h4 {
      color: #4b5563;
      font-weight: 500;
      font-size: 30px;
      line-height: 28px;
    }
    p {
      padding-top: 10px;
      color: #6d7280;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .button-section {
    margin-top: 48px;
    margin-bottom: 8px;
    button {
      width: 100%;
      background-color: #8c920b;
      color: #ffffff;
    }
  }
}
