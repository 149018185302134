.dimesion-room-section {
  .top-section {
    border-radius: 12px;
    padding: 8px 7px;
    border: 1px solid #7b7d51;
    button {
      color: #7e9db1;
      font-weight: 800;
      font-size: 10px;
      line-height: 16px;
    }
  }
  .room-selection-section {
    margin-top: 31px;

    .home-data {
      i {
        font-size: 32px;
        color: #8c920b;
      }
      h4 {
        font-weight: 600;
        font-size: 18px;
        color: #4b5563;
      }
    }
    .right-section {
      h4 {
        color: #6d7280;
        font-weight: 600;
        font-size: 11px;
        line-height: 20px;
      }
    }
    .room-addition {
      margin-top: 14px;
      padding: 14px 10px;
      width: 100%;
      height: 85px;
      background-color: #f7f8cd;
      border-radius: 6px;
      border: 2px dashed #a4a4a4;
      .right-section {
        h3 {
          margin: 0 0 2px 0;
          color: #393939;
          font-weight: 600;
          font-size: 18px;
          letter-spacing: -0.3px;
        }
        p {
          margin: 0;
          color: #585858;
          font-weight: 400;
          font-size: 14px;
          letter-spacing: -0.3px;
        }
      }
    }
    .room-section {
      margin-top: 32px;
      max-height: calc(100vh - 450px);
      overflow-y: scroll;
      padding-right: 4px;
      margin-right: 2px;

      &::-webkit-scrollbar {
        width: 8px;
        background-color: #f1f1f1;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
      }

      &::-webkit-scrollbar-thumb {
        background: #8c920b;
        border-radius: 10px;
        border: 2px solid #f1f1f1;
        min-height: 40px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #7b7d51;
      }

      i {
        cursor: pointer;
      }
      h1 {
        margin-top: 75px;
        font-weight: 700;
        font-size: 24px;
        color: #aeb4bc;
      }
      h4 {
        color: #4b5563;
        margin: 0;
        font-weight: 600;
        font-size: 18px;
      }
      h5 {
        margin-bottom: 20px;
        color: #4b5563;
        font-weight: 600;
        font-size: 18px;
      }
      .room-title {
        cursor: pointer;
        margin: 0 !important;
        border-radius: 8px 8px 0 0;
        padding: 20px 16px;
        background-color: #ffffff;
        h3 {
          margin: 0 !important;
          color: #6d7280;
          font-weight: 500;
          font-size: 18px;
          list-style: 21px;
          letter-spacing: -0.32px;
        }
      }
      .room {
        border-radius: 0 0 8px 8px;
        padding: 20px 16px;
        background-color: #ffffff;
        form {
          label {
            color: #6d7280;
            font-weight: 600;
            font-size: 10px;
            line-height: 20px;
          }
          input {
            margin-top: 11px;
            background-color: #f4f4f4;
            color: #7d868d;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.5px;
            border-radius: 8px;
            padding: 15px 10px 15px 16px;
            width: 100%;
            border: none;
            outline: none;
          }
        }
      }
    }
  }
  .bottom-section {
    label {
      color: #6d7280;
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
    }
    input {
      padding-left: 7px;
      width: 100%;
      height: 50px;
      background-color: #ffffff;
      border: none;
      border-radius: 8px;
    }
  }
  .button-section {
    position: sticky;
    bottom: 0;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .calendar-section {
    h5 {
      margin-top: 25px;
      color: #909090;
      font-weight: 600;
      font-size: 24px;
      line-height: 21px;
    }
    .chosen-calendar {
      padding: 10px;
      .chosen-text {
        h6 {
          margin: 0;
          font-weight: 700;
          font-size: 20px;
          color: #8c920b;
        }
      }
      .chosen-date {
        h6 {
          margin: 0;
          font-weight: 600;
          font-size: 20px;
          color: #6d7280;
        }
      }
      .chosen-time {
        h6 {
          margin: 0;
          font-weight: 600;
          font-size: 20px;
          color: #6d7280;
        }
      }
    }
    .Mui-selected {
      background-color: #e5e8ab !important;
    }
    .MuiPickersDay-root {
      color: #374151;
    }
    .MuiDateCalendar-root {
      background-color: #ffffff;
      width: 355px;
      height: 336px;
      margin: 0 auto;
    }
    .MuiStack-root {
      border-radius: 12px;
      padding: 0 !important;
      background-color: #ffffff;
    }
    .Mui-selected:hover {
      background-color: #e5e8ab;
    }
    .MuiPickersCalendarHeader-root {
      .MuiPickersCalendarHeader-label {
        font-size: 18px;
      }
      margin: 0;
    }
    .time-selection {
      border-radius: 12px;
      .right-side {
        padding-left: 154px !important;
      }
    }
  }
  .modal-footer {
    button {
      background-color: #8c920b;
      color: #ffffff;
      padding: 14px;
    }
  }
  .modal-dialog {
    min-height: 266px;
    margin: 0;
    padding: 0;
    width: 92%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
  }
}
.error-input {
  border: 2px solid red;
}
