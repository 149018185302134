.shops-section {
  .search-section {
    margin-top: 30px;
    margin-bottom: 36px;
    .input-section {
      position: relative;
      input {
        border-radius: 8px;
        width: 100%;
        height: 50px;
        outline: none;
        border: none;
        padding: 4px 20px;
      }
      input::placeholder {
        font-weight: 500;
        font-size: 18px;
        color: #6d7280;
      }
      i {
        font-size: 18px;
        color: #3e434e;
        cursor: pointer;
        position: absolute;
        top: 18px;
        right: 18px;
      }
    }
  }
  .shop-detail-section {
    background-color: #ffffff;
    min-height: 100px;
    border-radius: 8px;
    .left-section {
      .image-section {
        img {
          width: 62px;
          height: 62px;
          border-radius: 8px;
        }
      }
      .name-phone-section {
        .name-section {
          h6 {
            color: #000000;
            font-weight: 700;
            font-size: 20px;
            margin: 0;
          }
        }
        .phone-section {
          p {
            color: #000000;
            font-weight: 400;
            font-size: 16px;
            margin: 0;
          }
        }
      }
    }
  }
}
