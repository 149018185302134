.my-home-details-section {
  h4 {
    color: #101010;
    font-weight: 600;
    font-size: 14px;
  }
  .top-section {
    img {
      width: 100%;
      border-radius: 12px 12px 0 0;
    }
    .text-section {
      background-color: var(--primary-color);
      border-radius: 0 0 12px 12px;
      h4 {
        padding: 10px 26px 11px 25px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
      }
    }
  }
  .middle-section {
    margin-bottom: 30px;
    .section {
      cursor: pointer;
      padding: 12px 19.5px 12px 21.5px;
      width: 100%;
      height: 48px;
      background-color: #ffffff;
      h2 {
        margin-left: 14px;
        color: #101010;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
      .right-section {
        .detail-section {
          i {
            color: #aaaaaf;
          }
        }
      }
    }
    .section-1 {
      border-radius: 12px 12px 0 0;
    }
    .section-2 {
      border-radius: 0 0 12px 12px;
    }
  }
  .bottom-section {
    margin-bottom: 30px;
    .section {
      padding: 12px 19.5px 12px 21.5px;
      width: 100%;
      height: 48px;
      background-color: #ffffff;
      h2 {
        margin-left: 14px;
        color: #101010;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
      .right-section {
        .detail-section {
          i {
            color: #aaaaaf;
          }
        }
      }
    }
    .section-1 {
      border-radius: 12px 12px 0 0;
    }
    .section-2 {
      border-radius: 0 0 12px 12px;
    }
  }
}
