.account-details-section {
  .top-section {
    margin-top: 18px;
    margin-bottom: 25px;
    .username-section {
      margin-bottom: 14px;
      background-color: #ffffff;
      width: 100%;
      height: 85px;
      padding: 10px;
      border-radius: 12px;
      .left-section {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        img {
          width: 65px;
          border-radius: 50%;
        }
      }
      .right-section {
        .name-user-section {
          .name-section {
            h1 {
              color: #101010;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
            }
          }
          .user-section {
            p {
              color: #aaaaaf;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
    .edit-profile-section {
      button {
        background-color: #ffffff;
        width: 100%;
        height: 48px;
        border: 1px solid #8c920b;
        padding: 12px 14px;
      }
      .inner-section {
        h2 {
          color: #101010;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
  .middle-section {
    form {
      .name-surname-section,
      .email-section,
      .phone-number-section,
      .adress-section {
        border-bottom: 1px solid #d2d5da;
        margin-bottom: 20px;
      }
      input {
        color: #101010;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding: 10px 0;
        background-color: transparent;
        border: none;
        outline: none;
      }
      label {
        color: #9ca3af;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
