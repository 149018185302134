.account-contact-section {
  .top-section {
    margin-top: 108px;
    margin-bottom: 58px;
    .section {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 12px;
      padding: 20px;
      max-height: 64px;
      border: 1px solid #d2d5da;
      h5 {
        margin: 0 !important;
        color: #232323;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
      i {
        margin-right: 16px;
        font-size: 24px;
        color: #8c920b;
      }
    }
  }
  .middle-section {
    margin-bottom: 26px;
    h2 {
      text-align: center;
      font-weight: 500;
      font-size: 24px;
      line-height: 20px;
    }
  }
  .bottom-section {
    padding: 20px;
    max-height: 152px;
    border: 1px solid #d2d5da;
    border-radius: 12px;
    .social-media-section {
      display: flex;
      align-items: center;
      i {
        margin-right: 16px;
        font-size: 24px;
        color: #8c920b;
      }
      a {
        margin: 0 !important;
        color: #333333;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
