.repair-history-section {
  .top-section {
    margin-top: 26px;
    margin-bottom: 40px;
    i {
      color: var(--primary-color);
      font-size: 24px;
    }
    h4 {
      color: #4b5563;
      margin: 0 !important;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .bottom-section {
    height: auto;
    border-radius: 12px;
    background-color: #ffffff;
    .room-section {
      height: 60px !important;
      .left {
        i {
          color: var(--primary-color);
          font-size: 20px;
        }
      }
      .right {
        h6 {
          margin: 0 !important;
          color: #101010;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
        p {
          margin: 0 !important;
          color: rgb(0, 0, 0, 0.5);
          font-weight: 400;
          font-size: 10px;
          line-height: 16px;
        }
      }
      .horizontal-line {
        color: rgb(0, 0, 0, 0.5);
        width: 70%;
        margin-left: auto; /* Aligns the hr to the right */
        margin-right: 0;
      }
    }
  }
}
