.maintenance-section {
  .top-section {
    width: 100%;
    border-radius: 12px;
    height: 50px;
    background-color: #e6e6e6;
    margin-bottom: 24px;
    h5 {
      font-weight: 500;
      font-size: 16px;
      color: #ffffff;
      color: #1b1b1b;
    }
  }
  .top-middle-section {
    h6 {
      font-weight: 400;
      font-size: 14px;
      color: #1b1b1b;
    }
    p {
      margin-top: 12px;
      color: #333333;
      font-weight: 300;
      font-size: 14px;
    }
    .selected-rooms {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .room-btn {
        color: #8c920b;
        border: 1px solid #8c920b;
        height: 41px;
        font-weight: 400;
        font-size: 18px;
        border-radius: 8px;
        transition: 0.3s ease;
      }
      .room-btn:hover {
        color: #ffffff;
        background-color: #8c920b;
      }
    }
  }
  .home-data {
    i {
      font-size: 32px;
      color: #8c920b;
    }
    h4 {
      font-weight: 600;
      font-size: 12px;
      color: #4b5563;
    }
  }
  .middle-section {
    margin-bottom: 120px;
    .first {
      padding: 0 !important;
      font-weight: 400;
      font-size: 20px;
      line-height: 16px;
      display: flex;
      align-items: center;
    }
    .second {
      text-align: center;
      border: 1px solid #c4c4c4;
      padding: 8px;
      border-radius: 4px;
    }
    .fourth-raw {
      .first {
        font-weight: 700;
        color: #8c920b;
      }
      .second {
        font-weight: 700;
        border: 1px solid #8c920b;
        color: #8c920b;
      }
    }
  }
  .bottom-section {
    margin-top: 132px;
    .share-with-friend {
      width: 100%;
      border: 1px solid #8c920b;
      color: #8c920b;
      background-color: #ffffff;
    }
  }
  .modal-dialog {
    min-height: 266px;
    margin: 0;
    padding: 0;
    width: 92%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    .modal-header {
      padding: 16px 16px 0 16px;
      border: none !important;
      i {
        color: var(--primary-color);
      }
    }
    .modal-body {
      h6 {
        margin: 0 !important;
        color: #232323;
        font-weight: 400;
        font-size: 14px;
      }
      .social-media-section {
        margin-top: 10px;
        margin-bottom: 16px;
        i {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 56px;
          height: 56px;
          font-size: 28px;
          border: 1px solid #d7d7d7;
          border-radius: 50%;
          padding: 12px;
        }
        .fa-whatsapp {
          color: #25d366;
        }
        .fa-telegram {
          color: #34aadf;
        }
        .fa-facebook {
          color: #0866ff;
        }
        .fa-instagram {
          background: linear-gradient(
            45deg,
            #feda75,
            #fa7e1e,
            #d62976,
            #962fbf,
            #4f5bd5
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .fa-discord {
          color: #5865f2;
        }
      }
    }
    hr {
      width: 90%;
    }
    .modal-footer {
      border: none !important;
      .share-link-section {
        width: 100%;
        min-height: 56px;
        border: 1px solid #8c920b73;
        .left-section {
          h5 {
            margin: 0;
          }
        }
        .right-section {
          button {
            width: 88px;
            height: 44px;
            background-color: var(--primary-color);
            color: #ffffff;
          }
        }
      }
    }
  }
}
