.account-section {
  header {
    margin-top: 12px;
    margin-bottom: 33px;
  }
  .top-section {
    margin-bottom: 32px;
    .username-section {
      margin-bottom: 18px;
      background-color: #ffffff;
      width: 100%;
      height: 85px;
      padding: 10px;
      border-radius: 12px;
      .left-section {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        img {
          width: 65px;
          border-radius: 50%;
        }
      }
      .right-section {
        .name-user-section {
          .name-section {
            h1 {
              color: #101010;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
            }
          }
          .user-section {
            p {
              color: #aaaaaf;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
        .detail-section {
          i {
            color: #aaaaaf;
          }
        }
      }
    }
    .maintenance-section {
      .section {
        padding: 14px;
        border-radius: 12px;
        background-color: #ffffff;
        width: 100%;
        height: 96px;
        h5 {
          color: #101010;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }
      }
      img {
        margin-bottom: 8px;
      }
    }
  }
  .middle-section {
    margin-bottom: 30px;
    .connection-section {
      .section {
        padding: 12px 19.5px 12px 21.5px;
        width: 100%;
        height: 48px;
        background-color: #ffffff;
        h2 {
          margin-left: 14px;
          color: #101010;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
        .right-section {
          .detail-section {
            i {
              color: #aaaaaf;
            }
          }
        }
      }
      .section-1 {
        border-radius: 12px 12px 0 0;
      }
      .section-2 {
        border-radius: 0 0 12px 12px;
      }
    }
  }
  .bottom-section {
    margin-bottom: 53px;
    h6 {
      color: #101010;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
    .others-section {
      .section {
        padding: 12px 19.5px 12px 21.5px;
        width: 100%;
        height: 48px;
        background-color: #ffffff;
        h2 {
          margin-left: 14px;
          color: #101010;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
        .right-section {
          .detail-section {
            i {
              color: #aaaaaf;
            }
          }
        }
      }
      .section-1 {
        border-radius: 12px 12px 0 0;
      }
      .section-2 {
        border-radius: 0 0 12px 12px;
      }
    }
  }
  .exit-section {
    margin-bottom: 28px;
    .section {
      padding: 12px 19.5px 12px 21.5px;
      border-radius: 12px;
      width: 100%;
      height: 48px;
      background-color: #ffffff;
      h2 {
        margin-left: 14px;
        color: #101010;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
      .right-section {
        .detail-section {
          i {
            color: #aaaaaf;
          }
        }
      }
    }
  }
  .modal-dialog {
    margin: 0;
    padding: 0;
    width: 92%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    .list-group-item {
      cursor: pointer;
      border: none !important;
    }
    input {
      width: auto !important;
    }
    .modal-header {
      padding: 16px 16px 0 16px;
      border: none !important;
    }
  }
}
input[type="radio"] {
  appearance: none; /* Remove default styling */
  width: 5px;
  height: 5px;
  border: 2px solid #000; /* Initial border color */
  border-radius: 50%; /* Make it circular */
  position: relative;
  cursor: pointer; /* Optional: Makes the cursor a pointer on hover */
  padding: 5px;
  border: 1px solid #aaaaaa;
}

input[type="radio"]:checked {
  background-color: #8c920b; /* Change color when checked */
  border-color: #8c920b; /* Optional: change border color too */
}

.exit-footer {
  border: none;
  .exit-button {
    width: 100%;
    background-color: #fcdede;
    color: #f04437;
    padding: 12px 14px;
    border-radius: 7px;
    font-weight: 500;
    font-size: 16px;
  }
  .cancel-button {
    width: 100%;
    background-color: #ffffff;
    color: #101010;
    border: 1px solid #8c920b;
    padding: 12px 14px;
    border-radius: 7px;
    font-weight: 400;
    font-size: 16px;
  }
}
