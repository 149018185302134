.myforeman-section {
  .middle-section {
    margin-top: 42px;
    .foreman-details-section {
      background-color: #ffffff;
      width: 100%;
      height: 85px;
      padding: 10px;
      border-radius: 12px;
      .left-section {
        width: 54px;
        height: 54px;
        border-radius: 50%;
        overflow: hidden;
        display: inline-block;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .right-section {
        .name-user-section {
          .name-section {
            h1 {
              color: #101010;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
            }
          }
          .user-section {
            p {
              margin: 0;
              color: #000000;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
        .detail-section {
          i {
            color: #aaaaaf;
          }
        }
      }
    }
  }
}
